<template>
  <div class="mt-2 p-1.5 sm:container">
    <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
      <div class="flex space-x-3">
        <button @click="$router.go(-1)" class="text-md text-gray-dark cursor-pointer"><i
            class="fa-solid fa-arrow-left"></i>
        </button>
        <h1 class="text-md text-gray-dark capitalize" v-if="customer">{{ customer.company_name }}</h1>
      </div>
      <button class="self-end text-md" @click="create_ticket"><i class="fa-solid fa-headset"></i></button>
    </div>

    <div class="rounded p-2 flex items-center justify-between mt-5 mb-2 bg-white">
      <p class="text-gray-dark font-bold text-sm">Visualizza Ordini</p>
      <button @click="goToOrders"
              class="rounded px-2 py-0.5 text-sm font-bold bg-transparent text-primary border border-primary">
        Vai
      </button>
    </div>
    <div class="rounded p-2 flex items-center justify-between mb-2 bg-white">
      <p class="text-gray-dark font-bold text-sm">Crea Ordine</p>
      <span @click="create"
            class="rounded px-2 py-0.5 text-sm font-bold bg-transparent text-primary border border-primary">
        Crea
      </span>
    </div>

    <commercial :id="id" :customer="customer" :original_customer="original_customer" :load="load"></commercial>
    <users :id="id"></users>
    <branches :id="id"></branches>
    <div class="h-sm"></div>
  </div>
</template>

<script>
import Sales from "./sales.vue";
import Branches from "./branches.vue";
import Users from "./users.vue";
import Carts from "./carts.vue";
import Commercial from "./commercial.vue";

export default {
  name: "customer",
  components: {Commercial, Carts, Users, Branches, Sales},
  props: ['id'],
  data: () => ({
    customer: null,
    original_customer: null,
  }),
  methods: {
    goToOrders() {
      this.$router.push({name: 'customers.orders', params: {id: this.$props.id}});
    },
    create_ticket() {
      this.$emitter.emit('open_ticket', {
        flag: 1,
        ticket_class: 'App\\Domain\\Customer\\Models\\Customer',
        id: this.$props.id
      });
    },
    async load() {
      this.customer = (await axios.get('/clienti/customers/' + this.$props.id)).data;
      this.original_customer = Object.parse(Object.stringify(this.customer));
    },
    async create() {
      let l = this.$loading.show();
      try {
        let orderId = (await axios.post('/ordini/orders', {customer_id: this.id})).data;
        l.hide();
        this.go(orderId);
      } catch (e) {
        this.$toast.error(e.response.data.message);
      }
      l.hide();
    },
    go(orderId) {
      this.$router.push({name: 'orders.edit', params: {id: orderId}});
    }
  },
  mounted() {
    let l = this.$loading.show();
    try {
      this.load();
      l.hide();
    } catch (e) {
      this.$toast.error(e.response.data.message || e);
      l.hide();
      this.$router.push({name: 'customers.index'});
    }
  }
}
</script>
