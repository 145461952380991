<template>
  <div class="md:container md:px-1.5">
    <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
      <div class="flex w-full space-x-1.5 w-10/12 sm:w-11/12 lg:w-auto">
        <p @click="$router.go(-1)" class="text-md text-gray-dark cursor-pointer">
          <i class="fa-solid fa-arrow-left"></i>
        </p>
        <p class="text-md text-gray-dark capitalize truncate" v-if="ticket">{{ "Ticket " + id }}<span v-if="ticket"> - {{
            ticket.title
          }}</span>
        </p>
      </div>
      <button class="text-md lg:w-auto lg:hidden" @click="$refs.info.open()"><i
          class="fa-solid fa-ellipsis-vertical text-md"></i></button>
    </div>
    <div class="h-sm md:h-md"></div>
    <div class="flex flex-col space-y-2 lg:space-y-0 lg:space-x-2 lg:flex-row" v-if="ticket">
      <div class="lg:border rounded lg:border-gray-light w-full lg:w-2/3 bg-transparent lg:bg-white flex flex-col">
        <div class="p-2 flex flex-col space-y-1.5">
          <div v-for="item in conversation" class="flex flex-col"
               :class="item.side === 'left' ? 'items-start' : 'items-end text-right'">
                        <span class="text-xs mb-0.5">
                            <span v-if="item.by">Da: <span class="text-gray-dark mr-1">{{ item.by }}</span></span>
                            <span>in data: <span class="text-gray-dark">{{ item.date }}</span></span>
                        </span>
            <div class="max-w-full">
              <div
                  class="rounded p-1.5 space-x-1.5 text-ellipsis overflow-hidden"
                  :class="item.side === 'left' ? 'bg-white lg:bg-gray-light text-gray-dark' : 'bg-primary-light text-white'">
                <p style="white-space: preserve; word-wrap: break-word; " v-if="item.type === 'message'">{{
                    item.message
                  }}</p>
                <a v-else :href="item.url" target="_blank" class="hover:text-primary transition">
                  <i class="fa-solid fa-download "></i>
                  {{ item.title }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <hr class="mt-2 text-gray-light">
        <div v-if="ticket.canBeEdited" class="p-1.5 space-x-1.5 w-full hidden lg:flex">
          <div class="flex-grow space-y-1.5">
            <textarea v-model="message" name="message" placeholder="Messaggio"></textarea>
            <hr class="text-gray-light">
            <div class="pt-1 flex flex-col">
              <label>Carica un file</label>
              <input type="file" name="file" @change="uploadFile"
                     class="file:bg-primary file:rounded file:border-0 file:text-white file:text-sm file:px-2 file:py-1 file:font-bold">
            </div>
          </div>
          <div class="">
            <button class="btn btn-primary flex items-center justify-center h-4 w-4 lg:h-auto lg:w-auto"
                    type="submit" @click="send()" :class="{disabled: (!message || !valid_message) && !file}">
              <span class="hidden lg:block">Invia</span>
              <i class="fa-solid fa-paper-plane lg:ml-1.5 text-xs"></i>
            </button>
          </div>
        </div>
        <span v-else class="text-center hidden lg:block">Il ticket è stato chiuso</span>


        <div class="h-md lg:hidden"></div>
        <div v-if="ticket.canBeEdited"
             class="bg-white flex flex-col space-y-1 p-1.5 fixed inset-x-0 bottom-0 z-10 text-gray-dark text-xs lg:hidden">
          <div class="flex items-center space-x-2">
            <div class="flex-grow space-y-1.5 w-full">
              <textarea v-model="message" name="message" placeholder="Messaggio" style="resize: none"></textarea>
            </div>
            <div class="pt-1 flex flex-col">
              <label for="file" class="text-md text-gray"><i class="fa-solid fa-paperclip"></i></label>
              <input type="file" name="file" @change="uploadFile"
                     class="hidden" id="file">
            </div>
            <div class="">
              <button class="btn btn-primary flex items-center justify-center h-4 w-4 lg:h-auto lg:w-auto"
                      type="submit" @click="send()" :class="{disabled: (!message || !valid_message) && !file}">
                <span class="hidden lg:block">Invia</span>
                <i class="fa-solid fa-paper-plane lg:ml-1.5 text-xs"></i>
              </button>
            </div>
          </div>
          <p v-if="file" @click="file = null" class="cursor-pointer"><i class="fa-solid fa-paperclip"></i> {{
              fileName
            }} <i class="fa-solid fa-xmark"></i></p>
        </div>
        <span v-else
              class="bg-white block fixed inset-x-0 bottom-0 z-10 text-gray-dark text-xs lg:hidden text-center">Il ticket è stato chiuso</span>
      </div>
      <div class="hidden lg:block lg:w-1/3">
        <div class="flex flex-col bg-white border border-gray-light p-2 rounded overflow-x-auto">
          <h2 class="text-gray-dark text-md mb-2">Informazioni ticket</h2>
          <table class="text-sm w-full">
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">ID</td>
              <td class="text-right">{{ ticket.id }}</td>
            </tr>
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Data creazione</td>
              <td class="text-right">{{ $luxon(ticket.created_at) }}</td>
            </tr>
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Ultimo aggiornamento</td>
              <td class="text-right">{{ $luxon(ticket.updated_at) }}</td>
            </tr>
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Stato</td>
              <td class="text-right">{{ ticket.statusArray.description }}</td>
            </tr>
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Tipo</td>
              <td class="text-right">{{ ticket.typeArray.description }}</td>
            </tr>
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Oggetto</td>
              <td class="text-right">{{ ticket.subject }}</td>
            </tr>
            <template v-if="is_metadata && ticket.canBeEdited">
              <tr class="border-b-thin border-gray-extralight">
                <td class="pr-2 text-gray-dark pt-1">Priorità</td>
                <td class="text-right">
                  <select v-model="ticket.priority" name="" id="" @change="update">
                    <option v-for="p in priority_values" :value="p.value">{{ p.label }}</option>
                  </select>
                </td>
              </tr>
              <tr class="border-b-thin border-gray-extralight">
                <td class="pr-2 text-gray-dark pt-1">Scadenza</td>
                <td class="text-right">
                  <input type="date" v-model="ticket.expire" onfocus="'showPicker' in this && this.showPicker()" @change="update">
                </td>
              </tr>
              <tr class="border-b-thin border-gray-extralight" v-if="ticket.estimated_time">
                <td class="pr-2 text-gray-dark pt-1">Stima</td>
                <td class="text-right">
                  <p>{{ ticket.estimated_time }}
                    {{ ticket.estimated_time ? (ticket.uom ? ticket.uom : 'minuti') : null }}</p>
                </td>
              </tr>
            </template>
          </table>
        </div>
      </div>
    </div>
    <slideover ref="info" title="Informazioni ticket">
      <template v-slot:content>
        <table class="text-sm w-full">
          <tr class="border-b-thin border-gray-extralight">
            <td class="pr-2 text-gray-dark pt-1">ID</td>
            <td class="text-right">{{ ticket.id }}</td>
          </tr>
          <tr class="border-b-thin border-gray-extralight">
            <td class="pr-2 text-gray-dark pt-1">Data creazione</td>
            <td class="text-right">{{ $luxon(ticket.created_at) }}</td>
          </tr>
          <tr class="border-b-thin border-gray-extralight">
            <td class="pr-2 text-gray-dark pt-1">Ultimo aggiornamento</td>
            <td class="text-right">{{ $luxon(ticket.updated_at) }}</td>
          </tr>
          <tr class="border-b-thin border-gray-extralight">
            <td class="pr-2 text-gray-dark pt-1">Stato</td>
            <td class="text-right">{{ ticket.statusArray.description }}</td>
          </tr>
          <tr class="border-b-thin border-gray-extralight">
            <td class="pr-2 text-gray-dark pt-1">Tipo</td>
            <td class="text-right">{{ ticket.typeArray.description }}</td>
          </tr>
          <tr class="border-b-thin border-gray-extralight">
            <td class="pr-2 text-gray-dark pt-1">Oggetto</td>
            <td class="text-right">{{ ticket.subject }}</td>
          </tr>
          <template v-if="is_metadata && ticket.canBeEdited">
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Priorità</td>
              <td class="text-right">
                <select v-model="ticket.priority" name="" id="" @change="update">
                  <option v-for="p in priority_values" :value="p.value">{{ p.label }}</option>
                </select>
              </td>
            </tr>
            <tr class="border-b-thin border-gray-extralight">
              <td class="pr-2 text-gray-dark pt-1">Scadenza</td>
              <td class="text-right">
                <input type="date" v-model="ticket.expire" @change="update">
              </td>
            </tr>
            <tr class="border-b-thin border-gray-extralight" v-if="ticket.estimated_time">
              <td class="pr-2 text-gray-dark pt-1">Stima</td>
              <td class="text-right">
                <p>{{ ticket.estimated_time }}
                  {{ ticket.estimated_time ? (ticket.uom ? ticket.uom : 'minuti') : null }}</p>
              </td>
            </tr>
          </template>
        </table>
      </template>
    </slideover>
  </div>
</template>

<script>
import Slideover from "../../components/slideover.vue";

export default {
  name: "ticket",
  components: {Slideover},
  props: ['id'],
  data: () => ({
    file: null,
    ticket: null,
    conversation: null,
    message: null,
    priority_values: [
      {label: 'Normale', value: null},
      {label: 'Basso', value: 0},
      {label: 'Alto', value: 1}
    ],
    status_value: [
      'Aperto',
      'Analisi',
      'Test',
      'Chiuso'
    ],
    is_metadata: false,
    uom_values: [
      {label: 'minuti', value: null},
      {label: 'ore', value: 'ore'},
      {label: 'giorni', value: 'giorni'},
      {label: 'settimane', value: 'settimane'}
    ]
  }),
  methods: {
    async send() {
      let l = this.$loading.show();
      try {
        let formData = new FormData();
        if (this.file) formData.append('file', this.file);
        formData.append('message', this.message ? this.message.trim() : this.message);
        await axios.post('/assistenza/tickets/' + this.$props.id + '/messages', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        await this.load(false);
        this.message = null;
        this.file = null;
      } catch (e) {
        this.$toast.error(e.response.data.message);
      }
      l.hide();
    },
    update() {
      if(!isNaN(new Date(this.ticket.expire))) {
        console.log(this.ticket.expire);
        axios.put('/assistenza/tickets/' + this.$props.id, {
          expire: this.ticket.expire,
          priority: this.ticket.priority
        })
      }
    },
    async load(loading = true) {
      let l = null;
      if (loading) l = this.$loading.show();
      let data = (await axios.get('/assistenza/tickets/' + this.$props.id)).data;
      this.ticket = data.ticket;
      this.conversation = data.conversation;
      if (loading) l.hide();
    },
    uploadFile(e) {
      this.file = e.target.files[0];
    }
  },
  mounted() {
    this.is_metadata = (localStorage['is_metadata_appointee'] === 'true');
    this.load();
    setInterval(async () => {
      let data = (await axios.get('/assistenza/tickets/' + this.$props.id)).data;
      this.ticket = data.ticket;
      this.conversation = data.conversation;
    }, 30000);
  },
  computed: {
    fileName() {
      return this.file ? this.file.name : null;
    },
    valid_message() {
      return this.message && this.message.trim()
    }
  }
}
</script>

<style scoped>

</style>
