<template>
  <div class="container p-2">
    <template v-if="!sales_agent_show">
      <Transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="transform-all opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform-all opacity-0"
      >
      <span class="text-md text-gray-dark mb-2" v-if="data">
        <span v-if="!sales_agent_show">Ciao</span>
        <span v-else>Agente</span>
      {{ data.user }}
      </span>
      </Transition>
      <Transition
          enter-active-class="duration-300 ease-out"
          enter-from-class="transform-all opacity-0"
          enter-to-class="opacity-100"
          leave-active-class="duration-200 ease-in"
          leave-from-class="opacity-100"
          leave-to-class="transform-all opacity-0"
      >
        <div v-if="data && (deferredPrompt || safariDisplay) && !sales_agent_show"
             class="flex flex-col space-y-1.5 mt-2">
          <div class="flex justify-between items-center">
            <div class="flex space-x-2 items-center justify-center">
              <div class="flex justify-center items-center rounded-xl bg-white h-[4rem] w-[4rem] p-1">
                <img src="/icons/android-chrome-192x192.png" alt="Piattaforma Agenti">
              </div>
              <p class="text-gray-dark">Piattaforma Agenti</p>
            </div>
            <button class="rounded p-1 px-2 bg-primary hover:bg-primary-light text-white text-sm" @click="install">
              Installa
            </button>
          </div>
        </div>
      </Transition>
    </template>
    <template v-else>
      <div class="flex justify-between fixed bg-white inset-x-0 top-0 z-10 text-gray-dark text-xs p-2 space-x-3">
        <div class="flex w-full space-x-1.5 w-10/12 sm:w-11/12 lg:w-auto">
          <router-link :to="{name: 'agents.index'}" class="text-md text-gray-dark cursor-pointer"><i
              class="fa-solid fa-arrow-left"></i>
          </router-link>
          <p class="text-md text-gray-dark capitalize truncate">Agente <span v-if="data">{{ data.user }}</span></p>
        </div>
      </div>
      <div class="h-5"></div>
    </template>
    <Transition
        enter-active-class="duration-200 ease-in-out"
        enter-from-class="transform-all opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-100 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform-all opacity-0"
    >
      <div class="grid grid-cols-1 lg:grid-cols-3 mb-2 mt-2 lg:my-4 gap-0.5 transition-opacity duration-700 ease-in"
           v-if="data">
        <div class="flex items-center space-x-1.5 p-2 bg-white rounded-t lg:rounded-l">
          <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"><i
              class="fas fa-users"></i></div>
          <div>
            <p>Clienti in gestione</p>
            <p class="text-gray-dark font-bold text-md">{{ data.customers_count }}</p>
          </div>
        </div>
        <div class="flex items-center space-x-1.5 p-2 bg-white">
          <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"><i
              class="fas fa-boxes"></i></div>
          <div>
            <p>Totale ordini ({{ data.year || '-' }})</p>
            <p class="text-gray-dark font-bold text-md">{{ data.total_customers_orders || '-' }} €</p>
          </div>
        </div>
        <div class="flex items-center space-x-1.5 p-2 bg-white">
          <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"><i
              class="fa-solid fa-cart-shopping"></i></div>
          <div>
            <p>Carrelli aperti</p>
            <p class="text-gray-dark font-bold text-md">{{ data.opened_carts }}</p>
          </div>
        </div>
      </div>
    </Transition>
    <div v-if="!data" class="grid grid-cols-1 lg:grid-cols-3 mb-2 lg:my-4 gap-0.5">
      <div class="animate-pulse flex items-center space-x-1.5 p-2 bg-white rounded-t lg:rounded-l">
        <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"></div>
        <div class="w-full space-y-1.5">
          <div class="rounded-full bg-gray h-1.5 w-2/5"></div>
          <div class="rounded-full bg-gray h-2 w-1/5"></div>
        </div>
      </div>
      <div class="animate-pulse flex items-center space-x-1.5 p-2 bg-white rounded-t lg:rounded-l">
        <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"></div>
        <div class="w-full space-y-1.5">
          <div class="rounded-full bg-gray h-1.5 w-1/5"></div>
          <div class="rounded-full bg-gray h-2 w-2/5"></div>
        </div>
      </div>
      <div class="animate-pulse flex items-center space-x-1.5 p-2 bg-white rounded-t lg:rounded-l">
        <div class="h-sm w-sm flex items-center justify-center bg-primary-light rounded text-white text-md"></div>
        <div class="w-full space-y-1.5">
          <div class="rounded-full bg-gray h-1.5 w-2/5"></div>
          <div class="rounded-full bg-gray h-2 w-1/5"></div>
        </div>
      </div>
    </div>

    <Transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform-all opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-300 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform-all opacity-0"
    >
      <div class="p-2 flex flex-col space-y-1.5 bg-white rounded my-2"
           v-if="!reminder_is_closed && data && data.reminder && !sales_agent_show">
        <div class="flex space-x-1 justify-between items-center"><span class="text-gray-dark text-md">Promemoria</span>
          <button @click="reminder_is_closed = !reminder_is_closed"><i class="fa-solid fa-x text-md"></i></button>
        </div>
        <div v-for="item in data.reminder" class="flex flex-col"
             :class="item.side === 'left' ? 'items-start' : 'items-end text-right'">

          <div class="max-w-full">
            <div class="bg-gray-light rounded p-1.5 space-x-1.5 text-gray-dark text-ellipsis overflow-hidden">
              <span v-if="item.type === 'message'">{{ item.message }}</span>
              <a v-else :href="item.url" target="_blank" class="hover:text-primary h-full transition">
                <i class="fa-solid fa-download "></i>
                <span class="overflow-ellipsis">{{ item.title }}</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </Transition>


    <Transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform-all opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform-all opacity-0"
    >
      <div class="rounded p-2 flex items-center justify-between my-2 bg-white"
           v-if="data && is_super_agent && !sales_agent_show">
        <p class="text-gray-dark font-bold text-sm">I tuoi agenti</p>
        <router-link :to="{name: 'agents.index'}"
                     class="rounded px-2 py-0.5 text-sm font-bold bg-transparent text-primary border border-primary">
          Vai
        </router-link>
      </div>
    </Transition>
    <Transition
        enter-active-class="duration-300 ease-out"
        enter-from-class="transform-all opacity-0"
        enter-to-class="opacity-100"
        leave-active-class="duration-200 ease-in"
        leave-from-class="opacity-100"
        leave-to-class="transform-all opacity-0"
    >
      <div class="rounded space-y-2 my-2 bg-white flex flex-col" v-if="data">
        <Splide :options="{rewind: false, autoplay: false, pagination: true, arrows: false, classes: {
        pagination: 'splide__pagination absolute bottom-1',
        page: 'splide__pagination__page bg-secondary'
      }}">
          <SplideSlide>
            <div class="flex flex-col space-y-2 w-full p-2">
              <p class="text-md text-gray-dark">Totale Ordini {{ data.chart.labels[0] }} - {{
                  data.chart.labels[1]
                }}</p>
              <div class="justify-center items-center w-full min-h-[25rem] max-h-[30rem] hidden md:flex">
                <Bar id="orders" :options="chartOptions" :data="chartData"/>
              </div>
              <div class="flex justify-center items-center w-full min-h-[25rem] md:hidden">
                <Bar id="orders-mobile" class="h-full" :options="chartOptionsMobile" :data="chartData"/>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide>
            <div class="flex flex-col space-y-2 w-full p-2">
              <p class="text-md text-gray-dark">Totale Ordini {{ data.chart.labels[0] }} - {{
                  data.chart.labels[1]
                }}</p>
              <table class="text-gray-dark hidden w-full md:table">
                <tr>
                  <th></th>
                  <th class="text-start">{{ data.chart.labels[0] }}</th>
                  <th></th>
                  <th class="text-start">{{ data.chart.labels[1] }}</th>
                </tr>
                <tr>
                  <td>Totale Ordini Web</td>
                  <td class="">{{ data.chart.web_last_year }} €</td>
                  <th></th>
                  <td class="">{{ data.chart.web_this_year }} €</td>
                </tr>
                <tr>
                  <td>Percentuale Ordini Web</td>
                  <td class="">{{ data.chart.percentages.percentage_web_last_year }}%</td>
                  <th></th>
                  <td class="">{{ data.chart.percentages.percentage_web_this_year }}%</td>
                </tr>
                <tr>
                  <td>Totale Ordini Agente</td>
                  <td class="">{{ data.chart.agent_last_year }} €</td>
                  <th></th>
                  <td class="">{{ data.chart.agent_this_year }} €</td>
                </tr>
                <tr>
                  <td>Percentuale Ordini Agente</td>
                  <td class="">{{ data.chart.percentages.percentage_agent_last_year }}%</td>
                  <th></th>
                  <td class="">{{ data.chart.percentages.percentage_agent_this_year }}%</td>
                </tr>
                <tr class="text-base">
                  <td class="font-bold">Totale Ordini</td>
                  <td class="">{{ data.chart.total_last_year }} €</td>
                  <td class=""
                      :class="data.chart.percentages.delta_total_percentage <= 0 ? 'text-secondary' : 'text-availability-0'">
                    {{ data.chart.percentages.delta_total_percentage }}%
                  </td>
                  <td :class="data.chart.total_last_year >= data.chart.total_this_year ? 'text-secondary' : 'text-availability-0'">
                    {{ data.chart.total_this_year }} €
                  </td>
                </tr>
              </table>
              <div class="flex flex-col space-y-2 text-gray-dark md:hidden">
                <div class="flex flex-col">
                  <p class="font-bold">
                    {{ data.chart.labels[0] }}
                  </p>
                  <div class="flex justify-between">
                    <span>Totale Ordini Web</span><span>{{ data.chart.web_last_year }}€</span></div>
                  <div class="flex justify-between">
                    <span>% Ordini Web</span><span>{{ data.chart.percentages.percentage_web_last_year }}%</span></div>
                  <div class="flex justify-between">
                    <span>Totale Ordini Agente</span><span>{{ data.chart.agent_last_year }}€</span></div>
                  <div class="flex justify-between">
                    <span>% Ordini Agente</span><span>{{ data.chart.percentages.percentage_agent_last_year }}%</span>
                  </div>
                  <div class="flex justify-between text-base"><span
                      class="">Totale Ordini</span><span>{{ data.chart.total_last_year }}€</span></div>
                </div>
                <div class="flex flex-col">
                  <p class="font-bold">
                    {{ data.chart.labels[0] }}
                  </p>
                  <div class="flex justify-between">
                    <span>Totale Ordini Web</span><span>{{ data.chart.web_this_year }}€</span></div>
                  <div class="flex justify-between">
                    <span>% Ordini Web</span><span>{{ data.chart.percentages.percentage_web_this_year }}%</span></div>
                  <div class="flex justify-between">
                    <span>Totale Ordini Agente</span><span>{{ data.chart.agent_this_year }}€</span></div>
                  <div class="flex justify-between">
                    <span>% Ordini Agente</span><span>{{ data.chart.percentages.percentage_agent_this_year }}%</span>
                  </div>
                  <div class="flex justify-between text-base"><span class="">Totale Ordini</span><span
                      :class="data.chart.total_last_year >= data.chart.total_this_year ? 'text-secondary' : 'text-availability-0'">{{
                      data.chart.total_this_year
                    }}€</span>
                  </div>
                </div>
              </div>
            </div>
          </SplideSlide>
          <SplideSlide v-if="!sales_agent_show && is_super_agent">
            <div class="flex flex-col space-y-2 w-full p-2">
              <p class="text-md text-gray-dark">Totale Ordini {{ data.chart.labels[1] }} - Agenti</p>
              <agents_contribute_charts></agents_contribute_charts>
            </div>

          </SplideSlide>
        </Splide>
        <div class="flex justify-end w-full px-2 pb-1.5 text-xs md:text-sm ">
          <span>*Web: ordini fatti da sito dai clienti gestiti</span>
        </div>
      </div>
    </Transition>

    <div class="rounded space-y-2 my-2 bg-white flex flex-col" v-if="data">
      <div class="space-y-2 w-full p-2 flex flex-col" v-if="data">
        <p class="text-md text-gray-dark">Totale Ordini Mensili</p>
        <div class="justify-center items-center w-full min-h-[25rem] max-h-[30rem] flex">
          <months_chart :sales_agent_id="id"/>
        </div>
      </div>
      <div class="flex justify-end w-full px-2 pb-1.5 text-xs md:text-sm">
        <span>*Web: ordini fatti da sito dai clienti gestiti</span>
      </div>
    </div>

    <template v-if="!sales_agent_show">
      <div class="rounded p-2 flex items-center justify-between my-2 bg-white" v-if="data">
        <p class="text-gray-dark font-bold text-sm">Referral link</p>
        <button class="rounded px-2 py-0.5 text-sm font-bold bg-transparent text-primary border border-primary"
                @click="copy_referral">Copia
        </button>
      </div>
      <div class="animate-pulse rounded p-2 flex items-center justify-between my-2 bg-white" v-else>
        <div class="text-gray-dark font-bold text-sm">
          <div class="rounded rounded-full w-[10rem] h-1.75 bg-gray"></div>
        </div>
        <button class="rounded px-2 py-0.5 text-sm font-bold bg-transparent text-primary border border-primary">
          <div class="h-1.75 w-5 rounded rounded-full bg-primary animate-pulse"></div>
        </button>
      </div>
    </template>


    <div
        class="flex flex-col justify-center items-center space-y-1.5 mt-2 mb-2"
        v-if="data && data.users_with_opened_carts && data.users_with_opened_carts.length && !sales_agent_show">

      <div ref="opened_carts"
           class="flex flex-col bg-white rounded text-gray-dark space-y-1.5 p-2 w-full relative"
           style="transition: 1s; height: 16rem; overflow: hidden"
           :style="!(faded && show_more_toggle) ? { height : computedHeight } : {}">
        <p class="text-md">Utenti con carrelli aperti</p>

        <div class="w-full relative overflow-x-auto overflow-y-hidden">
          <table class="w-full table-auto border-spacing-4">
            <tr class="">
              <th class="text-start flex-grow">Utente</th>
              <th class="text-center">Numero prodotti</th>
              <th class="text-center">Ultimo Aggiornamento</th>
            </tr>
            <tr v-for="user_kpi in data.users_with_opened_carts">
              <td>
                <div class="cursor-pointer py-1.5" @click="openCart(user_kpi)">
                  <p class="font-bold text-xs">{{ user_kpi.company_name }} <span class="font-normal"
                                                                                 v-if="is_super_agent">seguito da {{ user_kpi.sales_agent }}</span>
                  </p>
                  <p>{{ user_kpi.name }}</p>
                </div>
              </td>
              <td class="text-center">{{ user_kpi.products }}</td>
              <td class="text-center">{{ user_kpi && user_kpi.updated_at ? user_kpi.updated_at : '-' }}</td>
            </tr>
          </table>

        </div>

        <div class="w-full absolute bottom-0 left-0 flex items-end justify-center" v-if="faded"
             :class="{'fade' : faded && show_more_toggle}">
          <button
              class="text-primary hover:text-primary-light" @click="show_more_toggle=!show_more_toggle">
            <span v-if="show_more_toggle">Mostra Tutto</span>
            <span v-else>Riduci</span>
          </button>
        </div>
      </div>
    </div>


    <div class="flex flex-col bg-white rounded text-gray-dark space-y-1.5 p-2"
         v-if="data && data.top_ten && data.top_ten.length">
      <p class="text-md">Clienti più redditizi <span class="text-sm">({{ data.year }})</span></p>
      <table class="w-full">
        <tr>
          <th class="text-start flex-grow">Cliente</th>
          <th class="text-start w-1/5">Volume Ordine</th>
        </tr>
        <tr v-for="customer in data.top_ten">
          <td>{{ customer.company_name }}</td>
          <td class="">{{ customer.total_order }} €</td>
        </tr>

      </table>
    </div>
    <div v-else-if="!data" class="animate-pulse flex flex-col bg-white rounded text-gray-dark space-y-1.5 p-2">
      <div class="h-3 w-1/4 rounded-full bg-gray"></div>
      <table class="w-full border-separate border-spacing-2">
        <tr class="my-2">
          <th class="flex-grow">
            <div class="rounded-full bg-gray rounded-full h-2 w-1/5"></div>
          </th>
          <th class="text-start w-1/5">
            <div class="rounded-full bg-gray rounded-full h-2 w-full"></div>
          </th>
        </tr>
        <tr class="my-2">
          <td>
            <div class="rounded-full bg-gray rounded-full h-2 w-3/5"></div>
          </td>
          <td class="">
            <div class="rounded-full bg-gray rounded-full h-2 w-4/5"></div>
          </td>
        </tr>
        <tr class="my-2">
          <td>
            <div class="rounded-full bg-gray rounded-full h-2 w-2/5"></div>
          </td>
          <td class="">
            <div class="rounded-full bg-gray rounded-full h-2 w-2/5"></div>
          </td>
        </tr>
        <tr class="my-2">
          <td>
            <div class="rounded-full bg-gray rounded-full h-2 w-3/5"></div>
          </td>
          <td class="">
            <div class="rounded-full bg-gray rounded-full h-2 w-2/5"></div>
          </td>
        </tr>
        <tr class="my-2">
          <td>
            <div class="rounded-full bg-gray rounded-full h-2 w-1/5"></div>
          </td>
          <td class="">
            <div class="rounded-full bg-gray rounded-full h-2 w-4/5"></div>
          </td>
        </tr>
        <tr class="my-2">
          <td>
            <div class="rounded-full bg-gray rounded-full h-2 w-4/5"></div>
          </td>
          <td class="">
            <div class="rounded-full bg-gray rounded-full h-2 w-3/5"></div>
          </td>
        </tr>
      </table>
    </div>
    <modal ref="safari">
      <div class="flex flex-col text-gray-dark font-normal space-y-1.75 p-1">
        <span class="text-md font-bold text-primary-light">Installazione</span>
        <p>Premi <i class="fa-solid fa-arrow-up-from-bracket text-primary text-md"></i></p>
        <p>Premi "<span class="text-primary">Aggiungi alla schermata Home <span
            class="ml-0.5 rounded border-thin text-primary px-1 py-0"><i class="fa-solid fa-plus"></i></span></span> "
        </p>
      </div>
    </modal>
    <cart_slide ref="cart" :user="user" :cart="cart"></cart_slide>

    <div class="h-sm"></div>
    <navbar v-if="!id"></navbar>

  </div>


</template>

<script>
import navbar from "../../components/navbar.vue";
import Cart_slide from "../../components/cart_slide.vue";
import {Bar} from 'vue-chartjs'
import {BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip} from "chart.js"
import {Splide, SplideSlide} from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css';
import Months_chart from "./months_chart.vue";
import Agents_contribute_charts from "./agents_contribute_charts.vue";
import Transitionexpand from "../../components/transition-expand.vue";


ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)
export default {
  name: "dashboard",
  components: {Transitionexpand, Agents_contribute_charts, Months_chart, Cart_slide, navbar, Bar, Splide, SplideSlide},
  props: ['id'],
  data: () => ({
    data: null,
    is_super_agent: false,
    deferredPrompt: null,
    computedHeight: 0,
    reminder_is_closed: false,
    user: {
      customer_id: null,
      id: null,
      name: null,
    },
    cart: {},
    chartData: {
      labels: [],
      datasets: []
    },
    chartOptionsMobile: {
      maintainAspectRatio: false,
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      }
    },
    chartOptions: {
      indexAxis: 'y',
      responsive: true,
      scales: {
        x: {
          stacked: true,
        },
        y: {
          stacked: true
        }
      },
    },
    show_more_toggle: true
  }),
  methods: {
    async load() {
      let params = this.$props.id ? new URLSearchParams([['sales_agent_id', this.$props.id]]) : null;
      this.data = (await axios.get('/dashboard', {params})).data;
      this.chartData.labels = this.data.chart.labels
      this.chartData.datasets = [
        {
          label: 'Web',
          data: [this.data.chart.web_last_year, this.data.chart.web_this_year],
          backgroundColor: 'rgb(255, 99, 132)'
        },
        {
          label: 'Agente',
          data: [this.data.chart.agent_last_year, this.data.chart.agent_this_year],
          backgroundColor: 'rgb(54, 162, 235)'
        }
      ];
    },
    async install() {
      if (this.safariDisplay) this.$refs.safari.open();
      else this.deferredPrompt.prompt();
    },
    copy_referral() {
      navigator.clipboard.writeText(this.data.referral_link);
      this.$toast.success("Referral link copiato");
    },
    openCart(data) {
      this.cart = {cart_type: data.type, id: data.cart_id};
      this.user = {id: data.user_id, customer_id: data.customer_id, name: data.name}
      this.$refs.cart.open();
    },
    initHeight() {
      this.$refs.opened_carts.style.height = 'auto';
      this.$refs.opened_carts.style.position = 'absolute';
      this.$refs.opened_carts.style.visibility = 'hidden';
      this.$refs.opened_carts.style.display = 'block';

      this.computedHeight = (parseFloat(getComputedStyle(this.$refs.opened_carts).height.replace('px', '')) + 40) + 'px';

      this.$refs.opened_carts.style.position = null;
      this.$refs.opened_carts.style.visibility = null;
      this.$refs.opened_carts.style.display = null;
      this.$refs.opened_carts.style.height = '16rem';
    },
  },
  async mounted() {
    if (typeof localStorage['is_super_agent'] === 'undefined') {
      localStorage['is_super_agent'] = (await axios.get('/super-agent')).data;
    }
    this.is_super_agent = localStorage['is_super_agent'] === 'true';
    await this.load();
    if (this.data.users_with_opened_carts.length && !this.sales_agent_show && this.$refs.opened_carts) this.initHeight();
  },
  created() {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault();
      this.deferredPrompt = e;
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
    })
  },
  beforeUnmount() {
    window.addEventListener('beforeinstallprompt', null);
    window.addEventListener('appinstalled', null);
  },
  computed: {
    safariDisplay: function () {
      const userAgent = navigator.userAgent.toLowerCase();
      const standalone = window.matchMedia('(display-mode: standalone)').matches;
      return /macintosh|ipad|iphone/.test(userAgent) && !(/chrome|android|opera|edge|chromium/.test(userAgent)) && !standalone;
    },
    sales_agent_show: function () {
      return typeof this.$props.id !== "undefined"
    },
    faded: function () {
      return this.data && this.data.users_with_opened_carts.length >= 4;
    }
  }
}
</script>

<style scoped>
.fade {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(255, 255, 255, 1.5) 75%);
  height: 100px;
  margin-top: -100px;
}
</style>
