<template>
  <div class="mt-2 p-1.5 lg:container">
    <div v-if="customer">
      <p class="text-md text-gray-dark mb-1">Anagrafica cliente</p>
      <div class="bg-white border border-thin border-gray-light rounded">
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-2 p-2 pb-0">
          <ic :e="errors['company_name']">
            <label class="uppercase text-xs text-gray-dark">Denominazione</label>
            <input type="text" v-model="customer.company_name">
          </ic>
          <ic :e="errors['tax_number']">
            <label class="uppercase text-xs text-gray-dark">Partita IVA</label>
            <input type="text" v-model="customer.tax_number">
          </ic>
          <ic :e="errors['fiscal_code']">
            <label class="uppercase text-xs text-gray-dark">Codice Fiscale</label>
            <input type="text" v-model="customer.fiscal_code">
          </ic>
          <ic :e="errors['sdi']">
            <label class="uppercase text-xs text-gray-dark">SDI</label>
            <input type="text" v-model="customer.sdi">
          </ic>
          <ic :e="errors['pec']">
            <label class="uppercase text-xs text-gray-dark">PEC</label>
            <input type="text" v-model="customer.pec">
          </ic>
          <ic :e="errors['iban']">
            <label class="uppercase text-xs text-gray-dark">IBAN</label>
            <input type="text" :class="errors['iban'] && errors['iban'].length && !original_customer.iban ? 'border border-secondary rounded' : ''" v-model="customer.iban">
          </ic>
        </div>
        <div class="flex justify-end p-2">
          <button class="btn btn-primary" @click="update">Aggiorna dati</button>
        </div>
      </div>

      <div>
        <p class="text-md text-gray-dark mt-4 mb-1">Condizioni commerciali</p>
        <div class="bg-white border border-thin border-gray-light rounded">
          <div class="grid grid-cols-2 lg:grid-cols-5 gap-2 p-2 pb-0">
            <div>
              <label>Listino</label>
              <input class="disabled" type="text" :value="customer.price_list" readonly disabled>
            </div>
            <ic :e="errors['discount_mode']">
              <label>Tipo Sconto</label>
              <select v-model="customer.discount_mode">
                <option v-for="discount_mode in discount_modes" :value="discount_mode.value">
                  {{ discount_mode.label }}
                </option>
              </select>
            </ic>
            <ic :e="errors['discount']">
              <label>Percentuale sconto</label>
              <select v-model="customer.discount">
                <option v-for="discount in discounts" :value="discount">{{ discount }}</option>
              </select>
            </ic>
            <div>
              <label>Spese di spedizione</label>
              <input class="disabled" type="text" :value="customer.shipping_fee" readonly disabled>
            </div>
            <div>
              <label>Limite spese spedizione</label>
              <input class="disabled" type="text" :value="customer.shipping_fee_threshold" readonly
                     disabled>
            </div>
          </div>
          <div class="flex justify-end p-2">
            <button class="btn btn-primary" @click="update">Aggiorna dati</button>
          </div>
        </div>
      </div>
      <div>
        <p class="text-md text-gray-dark mt-4">Metodi di pagamento associati</p>
        <div class="bg-white border border-thin border-gray-light rounded">
          <div class="p-2">
            <div class="flex flex-col mb-1.5" v-for="(group, type) in customer.payment_methods">
              <p class="text-base text-gray-dark">{{ type }}</p>
              <ul>
                <li v-for="payment_method in group" class="text-sm">
                  {{ payment_method.name }}
                </li>
              </ul>
            </div>
            <p class="text-primary cursor-pointer" v-if="can_bank"
                  @click="create_bank_ticket">Richiedi Contrassegno Bancario</p>
            <p class="text-primary cursor-pointer"
                  @click="create_iban_ticket">Richiedi altri metodi di pagamento aggiuntivi</p>
          </div>
        </div>
      </div>
      <div>
        <p class="text-md text-gray-dark mt-4">File associati</p>
        <div class="bg-white border border-thin border-gray-light rounded">
          <div class="grid grid-cols-2 xl:grid-cols-4 gap-3 p-2">
            <button class="flex" v-for="file in customer.files" @click="download_export(file.url)">
              <span class="text-gray-dark no-underline hover:underline"><i class="fa-solid mr-1 text-md"
                                                        :class="file.icon"></i>{{ file.name }}</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "commercial",
  props: {
    id: null
  },
  methods: {
    async update() {
      let l = this.$loading.show();
      try {
        await axios.put('/clienti/customers/' + this.$props.id, this.customer);
        this.$toast.success('Informazioni cliente aggiornate');
        this.errors = [];
        await this.load();
      } catch (e) {
        this.errors = e.response.data.errors;
      }
      l.hide();
    },
    async load() {
      let l = this.$loading.show();
      try {
        this.customer = (await axios.get('/clienti/customers/' + this.$props.id)).data;
        this.original_customer = JSON.parse(JSON.stringify(this.customer));
        if (!this.customer.tax_number) {
          this.discounts = [];
          this.discount_modes = [{value: 'FIXED', label: 'Fisso'}];
        }
        this.can_bank = !this.customer.payment_methods['Alla consegna'] || (this.customer.payment_methods['Alla consegna'] && (this.customer.payment_methods['Alla consegna'].findIndex(v => v.name === 'Contrassegno Assegno') === -1));
        if (!this.discounts.filter((v) => v === this.customer.discount).length) {
          this.discounts.push(this.customer.discount);
          this.discounts.sort();
        }
        l.hide();
      } catch (e) {
        this.$toast.error(e.response.data.message || e);
        l.hide();
        this.$router.push({name: 'customers.index'});
      }
    },
    async download_export(url) {
      try {
        let response = (await axios.get(url)).data;
        if(!window.open(response, '_blank'))
          window.location.href = response;
      }catch(e){
        this.$toast.error(e.response.data.message);
      }
    },
    create_iban_ticket() {
      if(this.original_customer.iban) {
        this.$emitter.emit('open_ticket', {
          flag: 1,
          ticket_class: 'App\\Domain\\Customer\\Models\\Customer',
          id: this.$props.id,
          title: 'Richiesta metodo di pagamento addizionale',
          message: `Ciao,
Per il cliente con iban: ${this.customer.iban || '[INSERIRE IBAN SE SERVE]'}
vorrei richiedere il seguente metodo di pagamento: `
        });
      } else {
        this.errors['iban'] = ['IBAN Mancante'];
        window.scrollTo({top: 0, behavior: 'smooth'});
      }
    },
    create_bank_ticket() {
        this.$emitter.emit('open_ticket', {
          flag: 1,
          ticket_class: 'App\\Domain\\Customer\\Models\\Customer',
          id: this.$props.id,
          title: 'Richiesta metodo di pagamento addizionale',
          open: true,
          message: `Ciao,
vorrei richiedere il seguente metodo di pagamento: Contrassegno Bancario`
        });
    },
  },
  mounted() {
    this.load();
  },
  data: () => ({
    errors: [],
    discounts: [50, 55, 60, 62],
    discount_modes: [{value: 'FIXED', label: 'Fisso'}, {value: 'DYNAMIC', label: 'Fasce'}],
    can_bank: true,
    original_customer: {},
    customer: {
      id: null,
      company_name: null,
      tax_number: null,
      fiscal_code: null,
      sdi: null,
      pec: null,
      iban: null,
      discount_mode: 'FIXED',
      discount: 50
    },
  }),

}
</script>

<style scoped>

</style>
